import React from "react";
import { connect } from "react-redux";
import { BUSINESS_INTELLIGENCE_APP_ID } from "../../../config";
import PortaleChildApp from "../../portale-child-app";

const BusinessIntelligenceView = ({ workspaceId }) => {
    return (
        <PortaleChildApp
            appId="BI"
            serviceId="BUSINESS-INTELLIGENCE"
            oneFrontAppId={BUSINESS_INTELLIGENCE_APP_ID}
            tenantId={workspaceId}
        />
    );
};

const mapStateToProps = state => ({
    workspaceId: state.services.workspaceId.data ? state.services.workspaceId.data.id : null
});

export default connect(mapStateToProps)(BusinessIntelligenceView);
